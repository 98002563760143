export function camel(str) {
  const camel = (str || "").replace(/-([^-])/g, (g) => g[1].toUpperCase());

  return capitalize(camel);
}

export function camelActual(str) {
  return (str || "").replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ""));
}

export function kebab(str) {
  return (str || "").replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export function capitalize(str) {
  str = str || "";

  return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
}

export function getLongId(id) {
  // btoa() but for node
  return Buffer.from(`gid://shopify/Product/${id}`, "binary").toString(
    "base64"
  );
}

export function findProduct(store, id) {
  return store.state.store.products.find((p) => p.id === id);
}

export function isOnSale(variants) {
  return variants.some((variant) => {
    return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
  });
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * max) + min;
}

export function randomString(length = 5) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
export function getMathRandom(length) {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  );
}
// Must be called in Vue context
export function goTo(id) {
  this.$vuetify.goTo(id).then(() => {
    if (!id) {
      return (document.location.hash = "");
    }

    if (history.replaceState) {
      history.replaceState(null, null, id);
    } else {
      document.location.hash = id;
    }
  });
}

export function toLocaleDateString(date, locales) {
  try {
    let internalLocales = ''
    if (!locales) {
      navigator.languages && navigator.languages.length > 0
        ? (internalLocales = navigator.languages[0])
        : (internalLocales = navigator.language);
    } else {
      internalLocales = locales
    }
    return new Date(date).toLocaleDateString(internalLocales, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: localStorage.getItem("timeZone"),
      timeZoneName: "short"
    });
  } catch (error) {
    console.warn(error);
    return "Invalid data";
  }
}

export const itemsPeriodicity = [
  { id: 0, name: "Día" },
  { id: 1, name: "Semana" },
  { id: 2, name: "Quincena" },
  { id: 3, name: "Mes" },
  { id: 4, name: "Trimestre" },
  { id: 5, name: "Semestre" },
  { id: 6, name: "Año" }
];
