<template>
  <v-app-bar class="primary card-toolbar" flat height="auto">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" style="font-size: 12px"> {{ title }}</span>
      </template>
      <span>{{ title }}</span>
    </v-tooltip>
    <v-spacer />
    <!-- <v-fab-transition>
    <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon dark x-small class="v-btn--example mx-0">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in itemsShare" :key="i" @click="() => {}">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-fab-transition> -->

    <v-btn @click.prevent="$emit('showFilters')" dark icon x-small>
      <v-icon>mdi-filter</v-icon>
    </v-btn>

    <help class="mx-0" :helpId="helpId" :xSmall="true" style="color: #fff" />

    <v-btn @click="$emit('deleteGraphic', graphic.id)" icon x-small dark class="mr-0">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
  export default {
    props: {
      graphic: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: '',
      },
      helpId: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        activeGrph: true,
        itemsShare: [
          {
            title: 'WhatsApp',
          },
          {
            title: 'E-mail',
          },
          {
            title: 'Telegram',
          },
        ],
      }
    },
  }
</script>
<style lang="scss">
  .card-toolbar {
    .v-toolbar__content {
      display: flex;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
    }
    .active-tag .v-btn--active {
      background-color: #003a56;
      color: #fff;
    }
    .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
      color: #fff;
      background-color: #003a56;
    }
  }
</style>
