<template>
  <v-form class="mx-3" v-model="valid" :key="index">
    <span>Seleccionar intérvalo de fecha por:</span>
    <v-bottom-navigation
      v-model="internalPeriodicity"
      color="white"
      background-color="teal lighten-3"
      style="box-shadow: none"
      height="30"
    >
      <v-btn v-for="item in itemsPeriodicity" :key="item.id" :value="item.id">
        <span>{{ item.name }}</span>
        <!-- <v-icon>mdi-calendar-range</v-icon> -->
      </v-btn>
    </v-bottom-navigation>

    <v-row class="mx-0">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :rules="[rules.required]"
          label="Desde"
          v-model="start"
          prepend-icon="mdi-calendar"
          type="date"
          :clearable="false"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          :rules="[rules.required]"
          label="Hasta"
          prepend-icon="mdi-calendar"
          v-model="end"
          type="date"
          :clearable="false"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import itemsPeriodicity from '@/data/periodicity'
  import { getIntervalDatesByPeriodicity } from '@/utils/dateFunctions'
  const today = new Date()
  export default {
    props: {
      startAt: {
        type: String,
        required: true,
        default: '',
      },
      endAt: {
        type: String,
        required: true,
        default: '',
      },
      periodicity: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        index: 0,
        valid: true,
        itemsPeriodicity: itemsPeriodicity,
        internalPeriodicity: 3,
        start: new Date(today.getFullYear(), today.getMonth() - 1, 1)
          .toISOString()
          .split('T')[0],
        end: new Date(today.getFullYear(), today.getMonth(), 0)
          .toISOString()
          .split('T')[0],
        rules: {
          required: (v) => !!v || 'Requerido.',
        },
      }
    },
    watch: {
      periodicity(newValue) {
        this.internalPeriodicity = newValue
      },
      internalPeriodicity(newValue) {
        const interval = this.getIntervalDatesByPeriodicity(today, newValue)
        this.start = interval.startAt
        this.end = interval.endAt
        this.index++
      },
      start(newValue) {
        this.$emit('update:startAt', newValue)
      },
      end(newValue) {
        this.$emit('update:endAt', newValue)
      },
      valid(newValue) {
        this.$emit('update:validDate', newValue)
      },
    },
    created() {
      this.internalPeriodicity = this.periodicity
    },
    methods: {
      getIntervalDatesByPeriodicity: getIntervalDatesByPeriodicity,
    },
  }
</script>
<style lang="scss">
  .date-filter {
    .active-tag {
      color: #fff;
      background-color: #000;
    }
  }
</style>
