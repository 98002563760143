export function handleMetricText(metricArray) {
  if (metricArray[1] === 1) {
    return modelsLibrary.find((obj) => obj.key === metricArray[0]).single;
  } else {
return modelsLibrary.find((obj) => obj.key === metricArray[0]).multiple;

  }
}
const modelsLibrary = [
  { key: "evaluations", single: "Evaluación", multiple: "Evaluaciones" },
  { key: "auditors", single: "Evaluador", multiple: "Evaluadores" },
  { key: "documents", single: "Documento", multiple: "Documentos" }
];
